import React from "react";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import { icon_fontSize } from "../..//utils/customStyle.js";
import { Typography } from "@mui/material";
const Footer = (props) => {
  return (
    <>
      <footer className="bg-light text-center text-sm-start">
        <div
          className="text-center p-3"
          style={{
            backgroundColor: "rgba(0, 0, 0, 0.2)",
          }}
        >
          <Typography variant="body1">
            Address: Ward no.-9, Howly Road, Pathsala(Bajali), Assam, 781325
          </Typography>
          <Typography variant="body1">Contact No.- 7635870050</Typography>
          <Typography variant="body1">
            Email: meseducationofficial@gmail.com
          </Typography>
          <div>
            <a
              href="https://www.facebook.com/mespathsala"
              target="_blank"
              rel="noreferrer"
            >
              <FacebookIcon sx={icon_fontSize} style={{ color: "#316FF6" }} />
            </a>
            {/* <a href="https://www.instagram.com/mes_781325/" target="_blank" rel="noreferrer">
             <InstagramIcon sx={icon_fontSize} style={{color: "red"}}/>
            </a> */}
            <a
              href="https://www.google.co.in/maps/place/Model+English+School/@26.5051947,91.163091,17z/data=!3m1!4b1!4m5!3m4!1s0x37597e83403b9865:0xb2c12a8104a3a77c!8m2!3d26.5051947!4d91.1652797"
              target="_blank"
              rel="noreferrer"
            >
              <LocationOnIcon sx={icon_fontSize} style={{ color: "green" }} />
            </a>
          </div>
          <Typography variant="body1">
            Copyright © 2016, Model English School.
          </Typography>
          <Typography variant="body1">All rights reserved.</Typography>
          <Typography variant="body1">
            Developed by:
            <a
              className="text-dark"
              target="_blank"
              href="https://www.facebook.com/mrigankx/"
              rel="noreferrer"
            >
              Mriganka Goswami
            </a>
          </Typography>
        </div>
      </footer>
    </>
  );
};

export default Footer;
