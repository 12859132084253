import { UPDATE_GALLERY } from "../constants/index";
const initialState = {
  // Your initial state properties here
  data: [],
};

const galleryReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_GALLERY:
      return {
        ...state,
        data: action?.payload,
      };
    default:
      return state;
  }
};

export default galleryReducer;
