import "./App.css";
import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./components/Home/Home";
import Admission from "./components/Admission/Admission";
import Career from "./components/Career/Career";
import Facilities from "./components/Facilities/Facilities";
import Feedback from "./components/Feedback/Feedback";
import Gallery from "./components/Gallery/Gallery";
import ManagingCommitee from "./components/ManagingCommitee/ManagingCommitee";
import MissionAndVision from "./components/MissionAndVision/MissionAndVision";
import Rules from "./components/Rules/Rules";
import Header from "./components/Header/Header";
import Footer from "./components/Footer/Footer";
import Staff from "./components/Staff/Staff";
import Popup from "./components/Popup/NoticeBoardPopup";
import Loader from "./components/Loader/Loader";
import Toast from "./components/Popup/ToastNotification/Toast.jsx"

import { useSelector } from "react-redux";
const App = React.memo(() => {
  const isLoading = useSelector((state) => state.loadingReducer.isLoading);

  return <>
          {isLoading && <Loader />}
      <Header />
      <BrowserRouter>
        <Popup />
        <Toast/>
        <Routes>
          <Route index exact path="/" element={<Home />} />
          <Route path="admission" element={<Admission />} />
          <Route path="vision" element={<MissionAndVision />} />

          <Route path="career" element={<Career />} />
          <Route path="facilities" element={<Facilities />} />
          <Route path="feedback" element={<Feedback />} />
          <Route path="gallery" element={<Gallery />} />
          <Route path="staff" element={<Staff />} />

          <Route path="committee" element={<ManagingCommitee />} />
          <Route path="rules" element={<Rules />} />
        </Routes>
        <Footer />
      </BrowserRouter>
    </>
});

export default App;
