import React from "react";

const MissionAndVision = (props) => {
  return (
    <>
      <div className="mission_and_vision">
        <div className="tiles">
          <h2 className="cursive_heading">Vision and Mission</h2>
          <hr />
          <div className="mission_content">
            <h6>
              To guide the students towards self-exposure with quality and
              social leadership, with blend of values and knowledge, by
              developing fluency in the use of English language that recognize
              and encourage talents of all kinds and degrees.
            </h6>
            {/*  */}
            <h6>
              To establish an ideal educational institution in Assam, that makes
              a difference to quality of human resource and uses methodology of
              child centered education that focuses academic excellence as well
              as, overall development of human personality.
            </h6>
          </div>
        </div>

        <hr />
        <div className="tiles">
          <h2 className="cursive_heading">Our Education Philosophy</h2>
          <hr />
          <div className="mission_content">
            <h6>
              Every child is an individual, with special social, emotional,
              intellectual, and physical qualities. Children are unique. They
              are individuals and no two children are alike: physically,
              emotionally, socially and intellectually, each child is a unique
              individual. Because children are unique, even if there are common
              needs and character istics that children of a particular age or
              stage of development share, they must be understood by their
              parents and teachers in their uniqueness, and their individuality
              must be respected. Teachers at Model English School thus consider
              this uniqueness and treat every student according to their needs.
            </h6>
          </div>
        </div>
      </div>
    </>
  );
};

export default MissionAndVision;
