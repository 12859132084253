import React, { useState } from "react";
import { useDispatch } from "react-redux";
import * as api from "../../api/index";
import fields from "./feedbackFields.js";
import {
  toggleLoading,
  showSuccessToast,
  showErrorToast,
} from "../../actions/index.js";
import {
  Send,
  Add,
  RemoveCircleOutline,
  RestartAlt,
} from "@mui/icons-material";
import InputComponent from "../Common/InputComponent.jsx";
const Feedback = (props) => {
  const initialFormInputState = {
    name: "",
    phone: "",
    email: "",
    subject: "",
    comment: "",
  };
  const [formInputs, setFormInputs] = useState(initialFormInputState);
  const dispatch = useDispatch();

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormInputs((prevValues) => ({ ...prevValues, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (
      formInputs.name !== "" &&
      formInputs.subject !== "" &&
      formInputs.comment !== ""
    ) {
      try {
        dispatch(toggleLoading(true));
        let { data: response } = await api.postFeedback(formInputs);
        if (response.status === "ok") {
          dispatch(showSuccessToast("Feedback Submitted Successfully!"));
        } else {
          const error = new Error(response.error);
          throw error;
        }
      } catch (error) {
        console.log(error);
        dispatch(showErrorToast("Oops! Something went wrong. Try again!"));
      }

      dispatch(toggleLoading(false));
      handleResetForm();
    }
  };

  const handleResetForm = () => {
    setFormInputs(initialFormInputState);
  };

  return (
    <>
      <div className="background_color feedback">
        <h1 className="cursive_heading">Feedback</h1>
        <hr />
        <div className="tiles page_forms">
          {fields.map((field) => (
            <InputComponent
              key={field.name}
              field={field}
              value={formInputs[field.name]}
              handleChangeInput={handleInputChange}
            />
          ))}
          <button
            className="btn btn-primary submitBtn"
            onClick={(e) => handleSubmit(e)}
          >
            Submit <Send />
          </button>
          <button
            onClick={() => handleResetForm()}
            className="btn btn-danger resetBtn"
          >
            Reset <RestartAlt />
          </button>
        </div>
      </div>
    </>
  );
};

export default Feedback;
