import React from "react";
import {Navigations}  from "../../resources/navigation";
import logo from "../../resources/images/logos/logo.webp"
import "./Header.css";
import NavBarComponent from "./NavBar/NavBar";
import useLoadInitialData from "./Header_Hook.js";

const Header = (props) => {
    const navigations = Navigations
    useLoadInitialData();
    return (
      <>
        <div className="header_container" id="header">
          <div className="container-fluid header">
            <a href="/">
              <img src={logo} alt="mes logo" loading="lazy" />
            </a>
            <a href="/">
              <p>Model English School</p>
              <p className="tagline">Co-operation is Success....</p>
            </a>
          </div>
         <NavBarComponent navigations={navigations}/>
        </div>
      </>
    );
};

export default Header;
