import { UPDATE_STAFF } from "../constants/index";
const initialState = {
  // Your initial state properties here
  data: [],
};

const staffReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_STAFF:
      return {
        ...state,
        data: action?.payload,
      };
    default:
      return state;
  }
};

export default staffReducer;
