import {
  UPDATE_GALLERY,
  UPDATE_NOTICES,
  UPDATE_STAFF,
  OPEN_POPUP,
  CLOSE_POPUP,
} from "../constants/index";

export const updateNotices = (newNotices) => ({
  type: UPDATE_NOTICES,
  payload: newNotices,
});

export const updateGallery = (newImages) => ({
  type: UPDATE_GALLERY,
  payload: newImages,
});
export const updateStaff = (newImages) => ({
  type: UPDATE_STAFF,
  payload: newImages,
});

export const openPopup = () => ({
  type: OPEN_POPUP,
});

export const closePopup = () => ({
  type: CLOSE_POPUP,
});

export const toggleLoading = (isLoading) => ({
  type: "TOGGLE_LOADING",
  payload: isLoading,
});
// Toasts
export const showSuccessToast = (message) => ({
  type: "SHOW_SUCCESS_TOAST",
  payload: { message },
});

export const showErrorToast = (message) => ({
  type: "SHOW_ERROR_TOAST",
  payload: { message },
});

export const resetSuccessMessage = () => ({
  type: "RESET_SUCCESS_MESSAGE",
});

export const resetErrorMessage = () => ({
  type: "RESET_ERROR_MESSAGE",
});
