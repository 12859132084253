import axios from "axios";

// const API = axios.create({ baseURL: "http://localhost:5000" });

const API = axios.create({
  baseURL: "https://meseducation-server.vercel.app/",
});
export const getNotices = () => API.get("/notices", {});
export const postFeedback = (data) => API.post("/feedback", data);
export const postAdmission = (data) => API.post("/admission", data);
export const postCareer = (data) => API.post("/career", data);
export const getGallery = () => API.get("/gallery");
export const getStaffs = () => API.get("/staffs");
