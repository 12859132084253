import React from "react";
import PricipalImage from "../../resources/images/managingCommittee/principal.webp";
import AsstSecretaryImage from "../../resources/images/managingCommittee/asstsecretary.webp";

const ManagingCommitee = (props) => {
  return (
    <>
      <h1 className="cursive_heading">Managing Commitee</h1>
      <hr />
      <div className="managing_commitee">
        <div className="mc_card">
          <img
            src="https://upload.wikimedia.org/wikipedia/commons/7/7c/Profile_avatar_placeholder_large.png"
            alt="President"
            loading="lazy"
          />
          <div className="container">
            <h4>President</h4>
            <h5>
              <b>Md. Nurul Islam</b>
            </h5>
            <h6>Social Activist & Businessman</h6>
          </div>
        </div>
        <div className="mc_card">
          <img
            src="https://upload.wikimedia.org/wikipedia/commons/7/7c/Profile_avatar_placeholder_large.png"
            alt="Secretary"
            loading="lazy"
          />
          <div className="container">
            <h4>Secretary</h4>
            <h5>
              <b>Mr. Apurba Kumar Choudhury</b>
            </h5>
            <h6>Social Activist & Businessman</h6>
          </div>
        </div>
        <div className="mc_card">
          <img src={AsstSecretaryImage} alt="Asst. Secretary" loading="lazy" />
          <div className="container">
            <h4>Asst. Secretary</h4>
            <h5>
              <b>Kaushik Sharma</b>
            </h5>
            <h6>
              B.Tech(Mechanical Engineering), KIIT University, Bhubaneshwar
            </h6>
            <h6>Contact Details: 8093908936 / kaushiksarma91@gmail.com</h6>
          </div>
        </div>
        <div className="mc_card">
          <img src={PricipalImage} loading="lazy" alt="Principal" />
          <div className="container">
            <h4>Principal</h4>
            <h5>
              <b>Gangadhar Kalita</b>
            </h5>
            <h6>Retired Headmaster, Rampur High School</h6>
            <h6>Contact Details: 8812840097</h6>
          </div>
        </div>
        <div className="mc_card">
          <img
            src="https://upload.wikimedia.org/wikipedia/commons/7/7c/Profile_avatar_placeholder_large.png"
            alt="Manager"
            loading="lazy"
          />
          <div className="container">
            <h4>Treasurer</h4>
            <h5>
              <b>Partha Pratim Sharma</b>
            </h5>
            <h6>B.COM</h6>
            <h6>Contact Details: 9957798742</h6>
          </div>
        </div>
        <div className="clear"></div>
      </div>
    </>
  );
};

export default ManagingCommitee;
