// useApiData.js
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as api from "../../api/index.js";
import { updateGallery, toggleLoading } from "../../actions/index.js";
import { isEqual } from "lodash";

const useLoadInitialData = () => {
  const dispatch = useDispatch();
  const prevData = useSelector((state) => state.galleryReducer.data);
  const loadInitialData = async () => {
    dispatch(toggleLoading(true));
    try {
      const { data: response } = await api.getGallery();
      if (response.status === "ok") {
        let { body: galleryData } = response;
        if (!isEqual(galleryData, prevData)) {
          dispatch(updateGallery(galleryData));
        }
      } else {
        const error = new Error(response.error);
        throw error;
      }
    } catch (error) {
    } finally {
      dispatch(toggleLoading(false));
    }
  };
  useEffect(() => {
    loadInitialData();
  }, []);
};

export default useLoadInitialData;
