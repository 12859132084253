const fields = [
    {
        name: 'name',
        label: "Name",
        type: 'text',
        isRequired: true
    },
    {
        name: 'phone',
        label: "Phone",
        type: 'phone',
        maxlength: "10",
        isRequired: false,
    },
    {
        name: 'email',
        label: "Email",
        type: 'email',
        isRequired: false,
    },
    {
        name: 'subject',
        label: "Subject",
        type: 'text',
        isRequired: true
    },
    {
        name: 'comment',
        label: "Comment",
        type: 'textarea',
        row: 6,
        isRequired: true
    }
]
export default fields;