import React from "react";

const Rules = (props) => {
  return (
    <>
      <div className="background_color rules">
        <h1 className="cursive_heading">Rules and Regulations</h1>
        <hr />
        <div style={{ width: "90%", textAlign: "justify" }}>
          <ol>
            <li>
              <span className="important_text">School uniform</span> is
              compulsory for the students within the school campus, unless
              notified otherwise.
            </li>

            <li>
              It is compulsory for the students to bring their
              <span className="important_text">
                {" "}
                identity cards issued by the school authority
              </span>{" "}
              to school every day. In case of loss of ID card a duplicate card
              will be issued upon submission of an amount as decided by
              authority.
            </li>

            <li>
              Parents/Guardians are advised not to send their children to school
              with
              <span className="important_text">
                {" "}
                valuable ornaments, watches and any other type of electronic
                gadgets.
              </span>
            </li>

            <li>
              Any
              <span className="important_text">
                {" "}
                unconventional hair style or any getup
              </span>{" "}
              that degrades the school uniform will not be tolerated.
            </li>

            <li>
              During school hours, no student will be allowed to leave the
              school premises without the written permission from the school
              authority. If a pupil is obliged to leave the school for some
              reason, a written request from the from the parents must be
              produced. The school will not be held responsible in the event of
              a student leaving the school premises without the written per
              mission from the authority. In such an event the student is liable
              to be expelled.
            </li>

            <li>
              <span className="important_text">
                Absenteeism and half day leave
              </span>{" "}
              must be noted in the school diary which needs to be approved by
              the school authority. In case of a pupil availing
              <span className="important_text">
                {" "}
                medical leave, a medical certificate
              </span>{" "}
              needs to be produced on the day of joining school after the sick
              leave.
            </li>
            <li>
              All conversation within the school premises will be made in
              <span className="important_text"> English</span> only.
            </li>

            <li>
              On the way to and from school the students are expected to behave
              in a befitting manner so as
              <span className="important_text">
                {" "}
                not to disgrace and dishonour the school's reputation.
              </span>{" "}
              If any pupil is caught doing so, he/she can be
              <span className="important_text"> expelled</span> from the school.
            </li>

            <li>
              Quarrelling and indulging in violent activities, carrying of any
              <span className="important_text"> dangerous instruments</span>,
              use of any form of
              <span className="important_text">
                {" "}
                tobacco, consumption of alcohol, use of any kinds of drugs
              </span>
              , carrying of
              <span className="important_text">
                {" "}
                objectionable literature
              </span>{" "}
              within the school premises and in school uniform outside the
              premises will result in
              <span className="important_text">
                {" "}
                immediate suspension from the school and possible expulsion.
              </span>
            </li>

            <li>
              School does not recognize
              <span className="important_text">
                {" "}
                Students' Union or any union activities
              </span>{" "}
              at any point of time within the school premises.
            </li>

            <li>
              Students must take proper care of school properties. In case of
              <span className="important_text">
                {" "}
                any intentional damage to school property by anyone
              </span>
              , it will lead to strict disciplinary action as may deem fit.
            </li>
          </ol>
        </div>
      </div>
    </>
  );
};

export default Rules;
