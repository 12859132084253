// // Popup.js
import React from "react";
import "./Popup.css";
import { useDispatch, useSelector } from "react-redux";
import { closePopup } from "../../actions/index";

const PopupComponent = () => {
  const dispatch = useDispatch();
  const isPopupOpen = useSelector((state) => state.popupReducer.isOpen);
  const notices = useSelector((state) => state.noticeReducer.data);

  const handleClosePopup = () => {
    dispatch(closePopup());
  };

  return (
    <>
      <div className={`popup ${isPopupOpen ? "open" : ""}`}>
        <div className="popup-content">
          <button className="close-btn" onClick={handleClosePopup}>
            &times;
          </button>

          <h3 align="center">Notice Board</h3>
          <hr />
          <ol>
            {notices?.length > 0 &&
              notices?.map((notice) => (
                <li key={notice.title}>
                  <h5>{notice.title}</h5>
                  <p style={{ fontSize: "15px" }}>- {notice.content}</p>
                </li>
              ))}
          </ol>
        </div>
      </div>
    </>
  );
};

export default PopupComponent;
