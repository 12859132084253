import React from "react";
import { useSelector } from "react-redux";
import useLoadInitialData from "./Gallery_Hook.js";

const Gallery = (props) => {
  useLoadInitialData();
  const imagesData = useSelector((state) => state.galleryReducer.data);

  return (
    <>
      <div className="background_color gallery">
        <h1 align="center" className="cursive_heading">
          MES Gallery
        </h1>
        <hr />

        <div
          className="gallery_body"
          style={{
            display: "grid",
            gridGap: 20,
            gridTemplateColumns: "repeat(auto-fit, 250px)",
            width: "100%",
            height: "100%",
          }}
        >
          {imagesData?.length ? (
            imagesData?.map((image) => {
              return (
                <div key={image.id} className="gallery_card">
                  <img
                    src={image.fileUrl}
                    alt=""
                    loading="lazy"
                    style={{ width: "100%" }}
                  />
                  <div className="img_desc">
                    <p align="center">{image.name}</p>
                  </div>
                  <div className="view_image_btn">
                    <button
                      className="btn"
                      data-toggle="modal"
                      data-info="<%=items[i].img.data%>"
                      data-target="#view_image"
                    >
                      <i className="fas fa-eye"></i>
                    </button>
                  </div>
                </div>
              );
            })
          ) : (
            <h2 style={{ color: "#fff" }}> No Data Yet</h2>
          )}
        </div>
      </div>
    </>
  );
};

export default Gallery;
