import React, { useState } from "react";
import fields from "./admissionFields";
import InputComponent from "../Common/InputComponent";
import { useDispatch } from "react-redux";
import * as api from "../../api/index";
import {
  toggleLoading,
  showSuccessToast,
  showErrorToast,
} from "../../actions/index.js";
//icons
import {
  Send,
  Add,
  RemoveCircleOutline,
  RestartAlt,
} from "@mui/icons-material";

const Admission = (props) => {
  const initialState = {
    name: "",
    dob: "",
    pob: "",
    gender: "none",
    class_name: "none",
    category: "none",
    permanent_address: "",
    current_address: "",
    father_name: "",
    father_academic_qualification: "none",
    father_occupation: "none",
    father_phone: "",
    father_email: "",
    mother_name: "",
    mother_academic_qual: "none",
    mother_occupation: "none",
    mother_phone: "",
    mother_email: "",
    local_guardian_name: "",
    loc_address: "",
    local_guardian_rel: "",
    local_guardian_no: "",
    mother_tongue: "",
    prev_insti: "",
    prev_class: "",
    sibilings: [{ id: 0, name: "", age: "", work: "" }],
  };
  const [formInputs, setFormInputs] = useState(initialState);
  const dispatch = useDispatch();

  const handleChangeInput = (e) => {
    const { name, value } = e.target;
    setFormInputs((prevValues) => ({ ...prevValues, [name]: value }));
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (
      formInputs.name !== "" &&
      formInputs.dob !== "" &&
      formInputs.gender !== "none" &&
      formInputs.class_name !== "" &&
      formInputs.father_phone !== ""
    ) {
      try {
        dispatch(toggleLoading(true));
        let { data: response } = await api.postAdmission(formInputs);
        if (response.status === "ok") {
          dispatch(showSuccessToast("Form Submitted Successfully!"));
        } else {
          const error = new Error(response.error);
          throw error;
        }
      } catch (error) {
        console.log(error);
        dispatch(showErrorToast("Oops! Something went wrong. Try again!"));
      }

      dispatch(toggleLoading(false));
      resetForm();
    }
  };

  const addRow = () => {
    setFormInputs((prev) => ({
      ...prev,
      sibilings: [
        ...prev.sibilings,
        { id: prev.sibilings.length, name: "", age: "", work: "" },
      ],
    }));
  };
  const removeRow = (id) => {
    if (formInputs.sibilings?.length > 1) {
      setFormInputs((prev) => {
        let sibilings = formInputs.sibilings.filter((row) => row.id !== id);
        return { ...prev, sibilings: sibilings };
      });
    }
  };

  const handleTableInputChange = (id, field, value) => {
    setFormInputs((prev) => {
      let sibilings = prev.sibilings.map((row) =>
        row.id === id ? { ...row, [field]: value } : row
      );
      return { ...prev, sibilings: sibilings };
    });
  };

  const resetForm = () => setFormInputs(initialState);

  return (
    <>
      <div className="background_color admission">
        <h1 align="center" className="cursive_heading">
          Admission Procedure
        </h1>
        <hr />
        <div className="admission_text">
          <ol>
            <li>
              Admission to classes{" "}
              <span className="important_text">I to IX</span> are made from
              April to July every year.
            </li>
            <li>
              For Online Admission, fill the form and submit hard copies of the
              documents in office.
            </li>
            <ol>
              <li>
                <span className="important_text">
                  Birth Certificate of the student.
                </span>
              </li>
              <li>
                <span className="important_text">
                  Pass certificate of the student from the office of the
                  institution attended last.
                </span>
              </li>
              <li>
                <span className="important_text">
                  3 copies of recent passport size colored photographs of the
                  student.
                </span>
              </li>
            </ol>
            <li>
              If a student is declared fit for admission, the guardian is
              required to deposit the school fee at school office during school
              hours, i.e 9AM - 3PM.
            </li>
          </ol>
        </div>
        <hr />
        <div className="tiles page_forms">
          <h1 align="center">Admission Form</h1>
          <hr />
          <form>
            {fields?.map((field) => (
              <InputComponent
                key={field.name}
                field={field}
                value={formInputs[field.name]}
                handleChangeInput={handleChangeInput}
              />
            ))}
            <hr />
            <div className="form-group">
              <label for="">Sibiling Details</label>
              <table className="field_wrapper_admission">
                <tr>
                  <th>Name</th>
                  <th>Age</th>
                  <th>Where studying/working</th>
                </tr>
                <tbody>
                  {formInputs.sibilings?.map((row) => {
                    return (
                      <tr key={row.id}>
                        <td>
                          <input
                            type="text"
                            value={row.name}
                            className="form-control"
                            onChange={(e) =>
                              handleTableInputChange(
                                row.id,
                                "name",
                                e.target.value
                              )
                            }
                          />
                        </td>
                        <td>
                          <input
                            type="number"
                            min="1"
                            value={row.age}
                            onChange={(e) =>
                              handleTableInputChange(
                                row.id,
                                "age",
                                e.target.value
                              )
                            }
                            className="form-control"
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            value={row.work}
                            onChange={(e) =>
                              handleTableInputChange(
                                row.id,
                                "work",
                                e.target.value
                              )
                            }
                            className="form-control"
                          />
                        </td>
                        <td>
                          <button
                            className="btn btn-danger"
                            onClick={() => removeRow(row.id)}
                          >
                            <RemoveCircleOutline />
                          </button>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
              <div className="btn btn-primary add_sib_button" onClick={addRow}>
                <Add />
              </div>
              <br />
            </div>
            {/*  */}
            <hr />
            <button
              type="submit"
              onClick={(e) => handleSubmit(e)}
              className="btn btn-primary submitBtn"
            >
              Submit <Send />
            </button>
            <button
              type="reset"
              className="btn btn-danger resetBtn"
              onClick={() => resetForm()}
            >
              Reset <RestartAlt />
            </button>
          </form>
        </div>
      </div>
    </>
  );
};

export default Admission;
