import React, { useEffect, useState } from "react";

const InputComponent = ({ field, value, handleChangeInput }) => {
  let { name, label, type, isRequired, values, maxlength, row, placeholder } =
    field;
  const [inputElement, setInputElement] = useState(<></>);
  const validatePhone = (e) => {};
  useEffect(() => {
    if (type === "text") {
      setInputElement(
        <input
          type="text"
          name={name}
          value={value}
          onChange={(e) => handleChangeInput(e)}
          placeholder={placeholder}
          className="form-control"
          required={isRequired || false}
        />
      );
    } else if (type === "date") {
      setInputElement(
        <input
          type="date"
          name={name}
          value={value}
          onChange={(e) => handleChangeInput(e)}
          className="form-control"
          required={isRequired || false}
        />
      );
    } else if (type === "radio") {
      setInputElement(
        <>
          {values.map((val) => (
            <>
              <input
                type="radio"
                name={name}
                onChange={(e) => handleChangeInput(e)}
                value={val.name}
                required={val?.isRequired || false}
              />
              {val.name?.toUpperCase()}
            </>
          ))}
        </>
      );
    } else if (type === "select") {
      setInputElement(
        <select
          className="form-control"
          name={name}
          value={value}
          onChange={(e) => handleChangeInput(e)}
          required={isRequired || false}
        >
          <option value="none" disabled>
            --Select--
          </option>
          {values.map((val) => (
            <option key={val} value={val}>
              {val}
            </option>
          ))}
        </select>
      );
    } else if (type === "textarea") {
      setInputElement(
        <textarea
          name={name}
          value={value}
          onChange={(e) => handleChangeInput(e)}
          placeholder={placeholder}
          rows={row}
          className="form-control"
          required={isRequired || false}
        ></textarea>
      );
    } else if (type === "email") {
      setInputElement(
        <input
          type="email"
          name={name}
          value={value}
          onChange={(e) => handleChangeInput(e)}
          className="form-control"
          placeholder={placeholder}
          required={isRequired || false}
        />
      );
    } else if (type === "phone") {
      setInputElement(
        <>
          <input
            type="number"
            name={name}
            value={value}
            onChange={(e) => handleChangeInput(e)}
            className="form-control"
            placeholder={placeholder}
            required={isRequired || false}
            onInput={(e) => validatePhone(e)}
            maxLength={maxlength}
          />
        </>
      );
    } else {
      // Default to a text input if the type is not recognized
      setInputElement(<input type="text" name="dynamicInput" />);
    }
  }, [handleChangeInput]);

  return (
    <>
      <div className="form-group">
        <label htmlFor={name}>{label}</label>
        {isRequired && <span className="required"> *</span>}
        {inputElement}
      </div>
      <hr />
    </>
  );
};

export default InputComponent;
