// reducers.js
const initialState = {
    isLoading: false,
  };
const loadingReducer = (state = initialState, action) => {
    switch (action.type) {
      case 'TOGGLE_LOADING':
        return {
          ...state,
          isLoading: action.payload,
        };
      default:
        return state;
    }
  };

  export default loadingReducer;