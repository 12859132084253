// Import necessary dependencies
import { configureStore } from "@reduxjs/toolkit";
import { thunk } from "redux-thunk";
import storage from "redux-persist/lib/storage";
import { persistReducer, persistStore } from "redux-persist";
import rootReducer from "./reducers/index"; // Import your combined reducers

const persistConfig = {
  key: "root",
  storage,
};
const persistedReducer = persistReducer(persistConfig, rootReducer);

// Configure store with Thunk middleware and Redux DevTools Extension
export const store = configureStore({
  reducer: persistedReducer,
  middleware: () => [thunk],
  devTools: true,
});

export const persistor = persistStore(store);
