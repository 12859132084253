// useApiData.js
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as api from "../../api/index.js";
import { updateNotices, toggleLoading } from "../../actions/index.js";
import { isEqual } from "lodash";

const useLoadInitialData = () => {
  const dispatch = useDispatch();
  const prevNotices = useSelector((state) => state.noticeReducer.data);
  const loadInitialData = async () => {
    try {
      const { data: response } = await api.getNotices();
      if (response.status === "ok") {
        let { body: noticeData } = response;
        if (!isEqual(noticeData, prevNotices)) {
          dispatch(updateNotices(noticeData));
        }
      } else {
        const error = new Error(response.error);
        throw error;
      }
    } catch (error) {}
  };
  useEffect(() => {
    dispatch(toggleLoading(true));
    loadInitialData();
    dispatch(toggleLoading(false));
  }, []);
};

export default useLoadInitialData;
