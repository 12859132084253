import { combineReducers } from "@reduxjs/toolkit";
import noticeReducer from "./noticeReducer";
import popupReducer from "./popupReducer";
import loadingReducer from "./animationLoader";
import toastReducer from "./toastReducer";
import galleryReducer from "./galleryReducer";
import staffReducer from "./staffReducer";

export const rootReducer = combineReducers({
  noticeReducer,
  popupReducer,
  loadingReducer,
  toastReducer,
  galleryReducer,
  staffReducer,
});

export default rootReducer;
