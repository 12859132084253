// useApiData.js
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as api from "../../api/index.js";
import { updateStaff, toggleLoading } from "../../actions/index.js";
import { isEqual } from "lodash";

const useLoadInitialData = () => {
  const dispatch = useDispatch();
  const prevData = useSelector((state) => state.staffReducer.data);
  const loadInitialData = async () => {
    dispatch(toggleLoading(true));
    try {
      const { data: response } = await api.getStaffs();
      if (response.status === "ok") {
        let { body: staffData } = response;
        let categorizeData = staffData.reduce(
          (acc, curr) => {
            if (
              curr.category === "Assistant Teacher" ||
              curr.category === "Principal" ||
              curr.category === "Kids Section"
            ) {
              acc["teachingStaff"].push(curr);
            } else if (curr.category === "Office Staff") {
              acc["officeStaff"].push(curr);
            } else if (curr.category === "Transport") {
              acc["transport"].push(curr);
            } else {
              acc["fourthGrade"].push(curr);
            }
            return acc;
          },
          { teachingStaff: [], officeStaff: [], fourthGrade: [], transport: [] }
        );

        if (!isEqual(categorizeData, prevData)) {
          dispatch(updateStaff(categorizeData));
        }
      } else {
        const error = new Error(response.error);
        throw error;
      }
    } catch (error) {
      console.log({ error });
    } finally {
      dispatch(toggleLoading(false));
    }
  };
  useEffect(() => {
    loadInitialData();
  }, []);
};

export default useLoadInitialData;
