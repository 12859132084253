const fields = [
    {
        name: 'name',
        label: "Name",
        type: 'text',
        isRequired: true
    },
    {
        name: 'dob',
        label: "Date of Birth",
        type: 'date',
        isRequired: true
    },
    {
        name: 'gender',
        label: "Gender",
        type: 'select',
        values: ['Male', 'Female', 'Other'],
        isRequired: true
    },
    {
        name: 'age',
        label: "Age",
        type: 'text',
        isRequired: true
    },
    {
        name: 'subjectName',
        label: "Applying for",
        type: 'select',
        isRequired: true,
        values: ['Assamese', 'English', 'Hindi', 'Mathematics', 'Science', 'Social Science', 'Computer Science', 'Drawing', 'Other']
    },
    {
        name: 'phone',
        label: "Mobile No.",
        type: 'phone',
        maxlength: "10",
        isRequired: true
    },
    {
        name: 'email',
        label: "Email",
        type: 'email',
        isRequired: false
    },
    {
        name: 'permanent_address',
        label: "Permanent Address",
        type: 'textarea',
        isRequired: true
    },
    {
        name: 'current_address',
        label: "Current Address",
        type: 'textarea',
        isRequired: true
    },
    {
        name: 'experience',
        label: "Experience(If Any)",
        type: 'text',
        isRequired: false
    }
    
]
export default fields;