import React from "react";
import useLoadInitialData from "./Staff_Hook";
import { useSelector } from "react-redux";
const Staff = (props) => {
  useLoadInitialData();
  const staffData = useSelector((state) => state.staffReducer.data);
  return (
    <>
      <div className="background_color staff_page">
        <h1 align="center" className="cursive_heading">
          Our School Staff
        </h1>
        <hr />
        <div>
          <h2>Teaching Staff</h2>
          <div
            style={{
              display: "grid",
              gridGap: 20,
              gridTemplateColumns: "repeat(auto-fit, 250px)",
              width: "100%",
              height: "100%",
            }}
          >
            {staffData?.teachingStaff?.map((data) => {
              return (
                <div key={data.id} className="img_card">
                  <img
                    src={data.fileUrl}
                    loading="lazy"
                    style={{ width: "100%" }}
                    alt="staff "
                  />
                  <div className="img_desc">
                    <p className="staffTitle" align="center">
                      {data.name}
                    </p>
                    <p className="staffQual" align="center">
                      {data.qualification || " "}
                    </p>
                    <p className="staffCategory" align="center">
                      {data.category}
                    </p>
                  </div>
                </div>
              );
            })}
          </div>
        </div>

        <hr />
        <div>
          <h2>Office Staff</h2>
          <div
            style={{
              display: "grid",
              gridGap: 20,
              gridTemplateColumns: "repeat(auto-fit, 250px)",
              width: "100%",
              height: "100%",
            }}
          >
            {staffData?.officeStaff?.map((data) => {
              return (
                <div key={data.id} className="img_card">
                  <img
                    src={data.fileUrl}
                    loading="lazy"
                    style={{ width: "100%" }}
                    alt="office "
                  />

                  <div className="img_desc">
                    <p className="staffTitle" align="center">
                      {data.name}
                    </p>
                    <p className="staffCategory" align="center">
                      {data.category}
                    </p>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        <hr />
        <div>
          <h2>Fourth Grade Staff</h2>
          <div
            style={{
              display: "grid",
              gridGap: 20,
              gridTemplateColumns: "repeat(auto-fit, 250px)",
              width: "100%",
              height: "100%",
            }}
          >
            {staffData?.fourthGrade?.map((data) => {
              return (
                <div key={data.id} className="img_card">
                  <img
                    src={data.fileUrl}
                    loading="lazy"
                    style={{ width: "100%" }}
                    alt="fourth "
                  />

                  <div className="img_desc">
                    <p className="staffTitle" align="center">
                      {data.name}
                    </p>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        <hr />
        <div>
          <h2>Transportation Staff</h2>
          <div
            style={{
              display: "grid",
              gridGap: 20,
              gridTemplateColumns: "repeat(auto-fit, 250px)",
              width: "100%",
              height: "100%",
              marginBottom: 30,
            }}
          >
            {staffData?.transport?.map((data) => {
              return (
                <div key={data.id} className="img_card">
                  <img
                    src={data.fileUrl}
                    loading="lazy"
                    style={{ width: "100%" }}
                    alt="fourth "
                  />

                  <div className="img_desc">
                    <p className="staffTitle" align="center">
                      {data.name}
                    </p>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
};

export default Staff;
