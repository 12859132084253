import React from "react";

//images
import ComputerLabImage from "../../resources/images/others/computer_lab.webp";
import ScienceLabImage from "../../resources/images/others/science lab.webp";
import TransportationImage from "../../resources/images/others/transportation.webp";
import LibraryImage from "../../resources/images/others/library.webp";
import ProjectorImage from "../../resources/images/others/projector.webp";
import KidsImage from "../../resources/images/others/kids_play.webp";
import FacultyImage from "../../resources/images/others/faculty.webp";
import HostelImage from "../../resources/images/others/hostel.webp";

const Facilities = (props) => {
  return (
    <>
      <div className="facilities_page" style={{ marginTop: "20px" }}>
        <h1 className="cursive_heading">FACILITIES</h1>
        <hr />
        <div style={{ padding: "20px" }}>
          <div className="row">
            <div className="col">
              <img
                className="fac_img"
                src={FacultyImage}
                loading="lazy"
                alt="Faculties"
              />
            </div>
            <div className="fac_desc col tiles">
              <h4>Dedicated, efficient and Well Trained Faculties</h4>
              <hr />
              <p>-- A vibrant group of experienced teachers.</p>
            </div>
          </div>
          <hr />
          <div className="row">
            <div className="fac_desc col tiles">
              <h4>Smart Technology Enabled Classrooms</h4>
              <hr />
              <p>
                -- The concept of smart class education is indeed a blessing to
                the students of the 21st Century. We at Model English School
                realized the importance of this change and introduced
                smart-technology enabled classrooms.
              </p>
            </div>
            <div className="col">
              <img
                className="fac_img"
                src={ProjectorImage}
                loading="lazy"
                alt="Classroom"
              />
            </div>
          </div>
          <hr />
          <div className="row">
            <div className="col">
              <img
                className="fac_img"
                src={ComputerLabImage}
                loading="lazy"
                alt="computer lab"
              />
            </div>
            <div className="fac_desc col tiles">
              <h4>Computer Lab</h4>
              <hr />
              <p>
                -- Computer laboratory of the school is accessible to students
                during and after the school hours. Web facility of the school
                can be used by students to do comprehensive research for their
                projects. Teachers also use the web facilities to prepare for
                their classes.
              </p>
            </div>
          </div>
          <hr />
          <div className="row">
            <div className="fac_desc col tiles">
              <h4>Science Lab</h4>
              <hr />
              <p>
                -- School has a well-equipped science laboratory to give a
                firsthand experience of the knowledge gained in the class.
                Equipment and reagents are design to take care of the need of
                the students for the Board examination.
              </p>
            </div>
            <div className="col">
              <img
                className="fac_img"
                src={ScienceLabImage}
                loading="lazy"
                alt="science lab"
              />
            </div>
          </div>
          <hr />
          <div className="row">
            <div className="col">
              <img
                className="fac_img"
                src={LibraryImage}
                loading="lazy"
                alt="library"
              />
            </div>
            <div className="fac_desc col tiles">
              <h4>Library</h4>
              <hr />
              <p>
                -- School's library provides a wide variety of books and
                journals. Local and national dailies are also provided to enable
                students to update with the current affairs.
              </p>
            </div>
          </div>
          <hr />
          <div className="row">
            <div className="fac_desc col tiles">
              <h4>Playroom</h4>
              <hr />
              <p>
                -- A special activity and playroom with high quality safe sports
                equipment for your little ones.
              </p>
            </div>
            <div className="col">
              <img
                className="fac_img"
                src={KidsImage}
                loading="lazy"
                alt="playroom"
              />
            </div>
          </div>
          <hr />

          <hr />
          <div className="row">
            <div className="col">
              <img
                className="fac_img"
                src={TransportationImage}
                loading="lazy"
                alt="transportation"
              />
            </div>
            <div className="fac_desc col tiles">
              <h4>Transportation</h4>
              <hr />
              <p>
                --Transport service is available. School authority regularly
                monitors it to ensure that your child is safely commuted to and
                from the school.
              </p>
            </div>
          </div>
          <div className="row">
            <div className="fac_desc col tiles">
              <h4>Hostel for Boys & Girls</h4>
              <hr />
              <p>-- Dedicated hostels for boys and girls.</p>
            </div>
            <div className="col">
              <img
                className="fac_img"
                src={HostelImage}
                loading="lazy"
                alt="HostelImage"
              />
            </div>
          </div>
          <hr />
        </div>
      </div>
    </>
  );
};

export default Facilities;
