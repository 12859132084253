import React, { useState, useRef } from "react";
import fields from "./careerFields";
import InputComponent from "../Common/InputComponent";
import { useDispatch } from "react-redux";
import * as api from "../../api/index";
import {
  toggleLoading,
  showSuccessToast,
  showErrorToast,
} from "../../actions/index.js";

//icons
import {
  Send,
  Add,
  RemoveCircleOutline,
  RestartAlt,
} from "@mui/icons-material";

const Career = (props) => {
  const initialState = {
    name: "",
    dob: "",
    gender: "none",
    age: "",
    subjectName: "none",
    phone: "",
    email: "",
    permanent_address: "",
    current_address: "",
    experience: "",
    qualifications: [
      { id: 0, course: "HSLC", college: "", passyear: "", cgpa: "" },
      { id: 1, course: "HS/HSSC", college: "", passyear: "", cgpa: "" },
    ],
    resume: null,
  };
  const [formInputs, setFormInputs] = useState(initialState);
  const dispatch = useDispatch();
  const inputRef = useRef();
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (
      formInputs.name !== "" &&
      formInputs.gender !== "none" &&
      formInputs.phone !== "" &&
      formInputs.age !== ""
    ) {
      try {
        dispatch(toggleLoading(true));
        const formdata = new FormData();
        for (let key in formInputs) {
          formdata.append(key, formInputs[key]);
        }
        let { data: response } = await api.postCareer(formdata);
        if (response.status === "ok") {
          dispatch(showSuccessToast("Form Submitted Successfully!"));
        } else {
          const error = new Error(response.error);
          throw error;
        }
      } catch (error) {
        console.log(error);
        dispatch(showErrorToast("Oops! Something went wrong. Try again!"));
      }

      dispatch(toggleLoading(false));
      resetForm();
    }
  };

  const resetForm = () => {
    setFormInputs(initialState);
    inputRef.current.value = "";
    inputRef.current.type = "file";
  };

  const handleChangeInput = (e) => {
    const { name, value } = e.target;
    setFormInputs((prevValues) => ({ ...prevValues, [name]: value }));
  };

  const addRow = () => {
    setFormInputs((prev) => ({
      ...prev,
      qualifications: [
        ...prev.qualifications,
        {
          id: prev.qualifications.length,
          course: "",
          college: "",
          passyear: "",
          cgpa: "",
        },
      ],
    }));
  };
  const removeRow = (id) => {
    if (formInputs.qualifications?.length > 1) {
      setFormInputs((prev) => {
        let qualifications = formInputs.qualifications.filter(
          (row) => row.id !== id
        );
        return { ...prev, qualifications: qualifications };
      });
    }
  };

  const handleTableInputChange = (id, field, value) => {
    setFormInputs((prev) => {
      let qualifications = prev.qualifications.map((row) =>
        row.id === id ? { ...row, [field]: value } : row
      );
      return { ...prev, qualifications: qualifications };
    });
  };
  return (
    <>
      <div className="career background_color">
        <h1 align="center" className="cursive_heading">
          Apply for a post
        </h1>
        <hr />
        <div className="page_forms tiles">
          {fields.map((field) => {
            return (
              <InputComponent
                key={field.name}
                field={field}
                value={formInputs[field.name]}
                handleChangeInput={handleChangeInput}
              />
            );
          })}
          <div className="form-group">
            <label>Academic Qualification Details</label>
            <span className="required"> *</span>
            <table className="field_wrapper">
              <thead>
                <tr>
                  <th>Degree/Course</th>
                  <th>School/College</th>
                  <th>Passing Year</th>
                  <th>%/CGPA:</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {formInputs.qualifications?.map((row) => (
                  <tr>
                    <td>
                      <input
                        type=" text"
                        className="form-control"
                        value={row.course}
                        onChange={(e) =>
                          handleTableInputChange(
                            row.id,
                            "course",
                            e.target.value
                          )
                        }
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        value={row.college}
                        onChange={(e) =>
                          handleTableInputChange(
                            row.id,
                            "college",
                            e.target.value
                          )
                        }
                        className="form-control"
                      />
                    </td>
                    <td>
                      <input
                        type="number"
                        value={row.passyear}
                        onChange={(e) =>
                          handleTableInputChange(
                            row.id,
                            "passyear",
                            e.target.value
                          )
                        }
                        className="form-control"
                      />
                    </td>
                    <td>
                      <input
                        type="number"
                        value={row.cgpa}
                        onChange={(e) =>
                          handleTableInputChange(row.id, "cgpa", e.target.value)
                        }
                        className="form-control"
                      />
                    </td>
                    <td>
                      <button
                        className="btn btn-danger"
                        onClick={() => removeRow(row.id)}
                      >
                        <RemoveCircleOutline />
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <div className="btn btn-primary add_button" onClick={addRow}>
              <Add />
            </div>
          </div>
          <hr />
          <div className="form-group">
            <label htmlFor="upload_resume">Upload CV/Resume(PDF only)</label>
            <input
              type="file"
              ref={inputRef}
              name="upload_resume"
              accept=".pdf"
              onChange={(e) => {
                const file = e.target.files[0];
                setFormInputs((prev) => ({ ...prev, resume: file }));
              }}
              className="form-control"
            />
          </div>
          <hr />

          <button
            className="btn btn-primary submitBtn"
            onClick={(e) => handleSubmit(e)}
          >
            Submit <Send />
          </button>
          <button
            type="reset"
            className="btn btn-danger resetBtn"
            onClick={() => resetForm()}
          >
            Reset <RestartAlt />
          </button>
        </div>
      </div>
    </>
  );
};

export default Career;
