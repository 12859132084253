export const Navigations = [
  {
    navigationId: 0,
    navigationName: "Home",
    navigationURL: "/",
    haveSubNavigation: false
  },
  {
    navigationId: 1,
    navigationName: "About Us",
    navigationURL: "",
    haveSubNavigation: true,
    subNavigations: [
      {
        navigationId: 0,
        navigationName: "Mission and Vision",
        navigationURL: "/vision",
      },
      {
        navigationId: 1,
        navigationName: "Managing Committee",
        navigationURL: "/committee",
      },
      {
        navigationId: 2,
        navigationName: "School Staff",
        navigationURL: "/staff",
      },
      {
        navigationId: 3,
        navigationName: "Rules & Regulations",
        navigationURL: "/rules",
      },
    ],
  },
  {
    navigationId: 2,
    navigationName: "Academics",
    navigationURL: "",
    haveSubNavigation: true,
    subNavigations: [
      {
        navigationId: 0,
        navigationName: "Holiday List",
        navigationURL: "https://drive.google.com/file/d/19YTBRiOQD2iQpBDJtO6Q0ux7rARdmXMs/view?usp=sharing",
  }
    //,
    //   {
    //     navigationId: 1,
    //     navigationName: "Results",
    //     navigationURL: "/results",
    //   }
    ],
  },
  {
    navigationId: 3,
    navigationName: "Facilities",
    navigationURL: "/facilities",
    haveSubNavigation: false
  },
  {
    navigationId: 4,
    navigationName: "Admissions",
    navigationURL: "/admission",
    haveSubNavigation: false
  },
  {
    navigationId: 5,
    navigationName: "Gallery",
    navigationURL: "/gallery",
    haveSubNavigation: false
  },
  {
    navigationId: 6,
    navigationName: "Careers with Us",
    navigationURL: "/career",
    haveSubNavigation: false
  },
  {
    navigationId: 7,
    navigationName: "Feedback",
    navigationURL: "/feedback",
    haveSubNavigation:false
  }
];
