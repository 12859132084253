const fields = [
    {
        name: 'name',
        label: "Name",
        type: 'text',
        isRequired: true
    },
    {
        name: 'dob',
        label: "Date of Birth",
        type: 'date',
        isRequired: true
    },
    {
        name: 'pob',
        label: "Place of Birth",
        type: 'text',
        isRequired: true
    },
    {
        name: 'gender',
        label: "Gender",
        type: 'select',
        values: ['Male', 'Female'],
        isRequired: true
    },
    {
        name: 'class_name',
        label: "Class for which admission is sought",
        type: 'select',
        isRequired: true,
        values: [1, 2, 3, 4, 5, 6, 7, 8, 9]
    },
    {
        name: 'category',
        label: "Category",
        type: 'select',
        isRequired: false,
        values: ["General", "Economically Weaker Section", "OBC", "ST", "SC"]
    },
    {
        name: 'permanent_address',
        label: "Permanent Address",
        type: 'textarea',
        isRequired: true
    },
    {
        name: 'current_address',
        label: "Current Address",
        type: 'textarea',
        isRequired: true
    },
    {
        name: 'father_name',
        label: "Father's Name",
        type: 'text',
        isRequired: true
    },
    {
        name: 'father_academic_qualification',
        label: "Academic Qualification",
        type: 'select',
        values: ["10th Passed", "12th Passed", "Bachelor's degree or above"],
        isRequired: true
    },
    {
        name: 'father_occupation',
        label: "Occupation",
        type: 'select',
        values: ["Government Job", "Private Job", "Business", "Other"],
        isRequired: true
    },
    {
        name: 'father_phone',
        label: "Mobile No.",
        type: 'phone',
        maxlength: "10",
        placeholder: "xxxxxxxxxx",
        isRequired: true
    },
    {
        name: 'father_email',
        label: "Email",
        type: 'email',
        isRequired: false
    },
    {
        name: 'mother_name',
        label: "Mother's Name",
        type: 'text',
        isRequired: true
    },
    {
        name: 'mother_academic_qual',
        label: "Academic Qualification",
        type: 'select',
        values: ["10th Passed", "12th Passed", "Bachelor's degree or above"],
        isRequired: false
    },
    {
        name: 'mother_occupation',
        label: "Occupation",
        type: 'select',
        values: ["Government Job", "Private Job", "Homemaker", "Business", "Other"],
        isRequired: false
    },
    {
        name: 'mother_phone',
        label: "Mobile No.",
        type: 'phone',
        maxlength: "10",
        placeholder: "xxxxxxxxxx",
        isRequired: false
    },
    {
        name: 'mother_email',
        label: "Email",
        type: 'email',
        isRequired: false
    },
    
    {
        name: 'local_guardian_name',
        label: "Name of Local Guardian(If Applicable)",
        type: 'text',
        isRequired: false
    },
    {
        name: 'loc_address',
        label: "Local Guardian Address",
        type: 'textarea',
        isRequired: false
    },
    {
        name: 'local_guardian_rel',
        label: "Relationship with the student",
        type: 'text',
        isRequired: false
    },
    {
        name: 'local_guardian_no',
        label: "Mobile No.",
        type: 'phone',
        maxlength: "10",
        placeholder: "xxxxxxxxxx",
        isRequired: false
    },
    {
        name: 'mother_tongue',
        label: "Mother Tongue",
        type: 'text',
        isRequired: true
    },
    {
        name: 'prev_insti',
        label: "Previous Institute(if any)",
        type: 'text',
        isRequired: false
    },
    {
        name: 'prev_class',
        label: "Previous Class(if any)",
        type: 'text',
        isRequired: false
    }
    
]
export default fields;