import React, { useState } from "react";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import { useDispatch } from "react-redux";
import { openPopup } from "../../../actions/index";

function NavBarComponent({ navigations }) {
  const [isMenuOpen, setMenuOpen] = useState(false);
  const dispatch = useDispatch();
  const handleOpenPopup = () => {
    dispatch(openPopup());
  };
  const toggleMenu = () => {
    setMenuOpen(!isMenuOpen);
  };
  return (
    <div
      style={{
        backgroundColor: "#2f4a99",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        padding: "0.1rem",
        paddingLeft: "0.5rem",
      }}
    >
      <Navbar style={{ color: "white" }} expand="lg" className="nav">
        <Navbar.Toggle aria-controls="basic-navbar-nav" onClick={toggleMenu} />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            {navigations.map((navigation) => {
              if (!navigation.haveSubNavigation) {
                return (
                  <Nav.Link
                    className="nav-item"
                    style={{ color: "#fff" }}
                    key={navigation.navigationId}
                    href={navigation.navigationURL}
                  >
                    {navigation.navigationName}
                  </Nav.Link>
                );
              } else {
                return (
                  <NavDropdown
                    title={navigation.navigationName}
                    key={navigation.navigationId}
                    id="basic-nav-dropdown"
                  >
                    {navigation.subNavigations.map((subNavigation) => {
                      return (
                        <NavDropdown.Item
                          className="nav-item"
                          key={subNavigation.navigationId}
                          href={subNavigation.navigationURL}
                        >
                          {subNavigation.navigationName}
                        </NavDropdown.Item>
                      );
                    })}
                  </NavDropdown>
                );
              }
            })}
          </Nav>
        </Navbar.Collapse>
      </Navbar>
      <button onClick={handleOpenPopup} className="glow-button">
        Notice Board
      </button>
    </div>
  );
}

export default NavBarComponent;
