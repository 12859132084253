import React from "react";
import { useNavigate } from "react-router-dom";
import ImageSlideshow from "./SlideShow/SlideShow.jsx";
//import images
import slide1 from "../../resources/images/carousel/image1.webp";
import slide2 from "../../resources/images/carousel/image2.webp";
import slide3 from "../../resources/images/carousel/image3.webp";
import slide4 from "../../resources/images/carousel/image4.webp";

import logo from "../../resources/images/logos/logo.webp";
import principalImage from "../../resources/images/others/principal.webp";

//import icons
import MenuBookSharpIcon from "@mui/icons-material/MenuBookSharp";
import PeopleIcon from "@mui/icons-material/People";
import DirectionsBusIcon from "@mui/icons-material/DirectionsBus";
import ScienceIcon from "@mui/icons-material/Science";
import LaptopIcon from "@mui/icons-material/Laptop";
import HouseIcon from "@mui/icons-material/House";
import { icon_fontSize } from "../../utils/customStyle.js";

const Home = (props) => {
  const navigate = useNavigate();
  const images = [slide1, slide2, slide4];

  return (
    <>
      <div className="slideshow-container">
        <ImageSlideshow images={images} timeInterval={3000} />
      </div>
      <div className="home_contents">
        <div className="tiles">
          <div className="home_header_container">
            <h1 align="center" className="home_headers">
              About MES &nbsp;
            </h1>
            <img src={logo} id="home_mes_logo" alt="MES logo" loading="lazy" />
          </div>
          <hr />
          <div className="home_page_content">
            <p className="home_page_p">
              Model English School was established in 2016 with an aim of
              presenting itself away from the crowd as a true English medium
              school which focuses on holistic development of the students. This
              gives an opportunity for the students of the town to access
              quality education comparable to any other Metropolitan city.
              Today, we are happy to say that we are known as one of the best
              school of the town. 7+ batches of students from our school
              appeared in HSLC examination and brought laurel for the school.
              Currently our high school section is under The Board of Secondary
              Education, Assam(SEBA).
            </p>
          </div>
        </div>
      </div>

      <div className="home_contents">
        <div className="tiles">
          <h1 align="center" className="home_headers">
            From Principal's Desk
          </h1>
          {/* comment */}
          <hr />
          <div className="home_page_content">
            <img src={principalImage} alt="principal" id="principal_img" />
            <p className="home_page_p">
              Dear Students and Parents! Welcome to Model English School. From
              its birth, MES is focused on holistic development of students. We
              believe that students should be imparted all round education to
              bring out their multidimensional character hidden within each
              child. Acquiring information alone will not make a child ready for
              life and thus the focus of education should be to build a healthy
              mind and spirit. We believe in providing an atmosphere for
              acquiring knowledge and support for their extracurricular
              activities to ensure multifaceted development. During our short
              journey, I am happy to share that, last few years have been
              successful and satisfying in terms of accomplishments and
              accolades in scholastic and co-scholastic area. I feel proud to
              acknowledge the contribution of a highly motivated, dedicated team
              of faculty, and non-teaching staff.
            </p>
          </div>
        </div>
      </div>

      <div className="facilities">
        <h1 className="cursive_heading">FACILITIES</h1>
        <div className="facility_boxes">
          <div className="card" onClick={() => navigate("/facilities")}>
            <PeopleIcon sx={icon_fontSize} />
            <hr />
            <h5>Well Trained & Experienced Faculty</h5>
          </div>
          <div className="card" onClick={() => navigate("/facilities")}>
            <MenuBookSharpIcon sx={icon_fontSize} />
            <hr />
            <h5>Well Maintained Library & Computer Lab</h5>
          </div>
          <div className="card" onClick={() => navigate("/facilities")}>
            <DirectionsBusIcon sx={icon_fontSize} />
            <hr />
            <h5>GPS Trackable Transportation</h5>
          </div>
          <div className="card" onClick={() => navigate("/facilities")}>
            <ScienceIcon sx={icon_fontSize} />
            <hr />
            <h5>Well Equipped Laboratory</h5>
          </div>
          <div className="card" onClick={() => navigate("/facilities")}>
            <div onClick={() => navigate("/facilities")}>
              <LaptopIcon sx={icon_fontSize} />
              <hr />
              <h5>Technology Enabled Classroom</h5>
            </div>
          </div>
          <div className="card" onClick={() => navigate("/facilities")}>
            <HouseIcon sx={icon_fontSize} />
            <hr />
            <h5>Hostel Facility(Boys & Girls)</h5>
          </div>
        </div>
      </div>

      <div className="row apply_online">
        <div className="flip-box col">
          <div className="flip-box-inner">
            <div className="flip-box-front">
              <div>Apply for Online Admission Here</div>
            </div>
            <div className="flip-box-back">
              <h2>Our Education Philosophy</h2>
              <hr />
              <p>
                "We cannot always build the future for our youth, but we can
                build our youth for the future." - Franklin D. Roosevelt
              </p>
              <button className="btn">
                <a href="/Admission">Apply Now</a>
              </button>
            </div>
          </div>
        </div>
        <div className="flip-box-ver col">
          <div className="flip-box-inner-ver">
            <div className="flip-box-front">
              <div>Download Our Prospectus Here</div>
            </div>
            <div className="flip-box-back-ver">
              <button className="btn">
                <a href="https://drive.google.com/file/d/1cBN9KBepSTEeSJ0cwe9lIGieIduvS_Gj/view?usp=sharing">
                  Get Your Copy
                </a>
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;
